import React from "react";
import { Link } from "gatsby";

import Logo from "../images/logo.svg";
import Facebook from "../images/icons/facebook.svg";
import Twitter from "../images/icons/twitter.svg";
// import Instagram from "../images/icons/instagram.svg";
import Linkedin from "../images/icons/linkedin.svg";
import Youtube from "../images/icons/youtube.svg";

export default function Footer() {
  return (
    <footer className="bg-mph-light-gray">
      <div className="container px-5 py-12 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">
        <div className="w-64 flex-shrink-0 md:mx-0 mx-auto text-center md:text-left">
          <Link to="/">
            <img src={Logo} />
          </Link>
          <span className="inline-flex sm:ml-auto sm:mt-0 mt-2 px-2 justify-center sm:justify-start">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://facebook.com/myproducthub"
              className="text-gray-500"
            >
              <img src={Facebook} alt="Facebook" />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://twitter.com/myproducthub"
              className="ml-3 text-gray-500"
            >
              <img src={Twitter} alt="Twitter" />
            </a>
            {/* <a
              href="https://instagram.com/myproducthub"
              className="ml-3 text-gray-500"
            >
              <img src={Instagram} alt="Instagram" />
            </a> */}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://linkedin.com/company/myproducthub"
              className="ml-3 text-gray-500"
            >
              <img src={Linkedin} alt="Linkedin" />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.youtube.com/channel/UCZ8-pUDgoaR-x-FFwoydsUA"
              className="ml-3 text-gray-500"
            >
              <img src={Youtube} alt="Youtube" />
            </a>
          </span>
        </div>
        <div className="flex-grow flex flex-wrap md:pl-20 -mb-10 md:mt-0 mt-10 md:text-left text-center">
          <div className="lg:w-1/4 md:w-1/2 w-full px-4">
            <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">
              Features
            </h2>
            <nav className="list-none mb-10">
              <li>
                <Link
                  to="/product/feedback"
                  className="text-gray-600 hover:text-gray-800"
                >
                  Feedback
                </Link>
              </li>
              <li>
                <Link
                  to="/product/testimonial"
                  className="text-gray-600 hover:text-gray-800"
                >
                  Testimonial
                </Link>
              </li>
              <li>
                <Link
                  to="/product/ideas"
                  className="text-gray-600 hover:text-gray-800"
                >
                  Idea Management
                </Link>
              </li>
              <li>
                <Link
                  to="/product/roadmaps"
                  className="text-gray-600 hover:text-gray-800"
                >
                  Roadmaps
                </Link>
              </li>
            </nav>
          </div>
          <div className="lg:w-1/4 md:w-1/2 w-full px-4">
            <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">
              Company
            </h2>
            <nav className="list-none mb-10">
              <li>
                <Link to="/about" className="text-gray-600 hover:text-gray-800">
                  About
                </Link>
              </li>
              <li>
                <Link
                  to="/pricing"
                  className="text-gray-600 hover:text-gray-800"
                >
                  Pricing
                </Link>
              </li>
              <li>
                <Link
                  to="/contact"
                  className="text-gray-600 hover:text-gray-800"
                >
                  Contact
                </Link>
              </li>
            </nav>
          </div>
          <div className="lg:w-1/4 md:w-1/2 w-full px-4">
            <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">
              Resources
            </h2>
            <nav className="list-none mb-10">
              <li>
                <Link to="/blog" className="text-gray-600 hover:text-gray-800">
                  Blog
                </Link>
              </li>
              <li>
                <Link
                  to="/glossary"
                  className="text-gray-600 hover:text-gray-800"
                >
                  Glossary
                </Link>
              </li>
              {/* <li>
                <a className="text-gray-600 hover:text-gray-800">
                  How To Videos
                </a>
              </li>
              <li>
                <a className="text-gray-600 hover:text-gray-800">Webinar</a>
              </li> */}
            </nav>
          </div>
          {/* <div className="lg:w-1/4 md:w-1/2 w-full px-4">
            <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">
              Support
            </h2>
            <nav className="list-none mb-10">
              <li>
                <a className="text-gray-600 hover:text-gray-800">Help Center</a>
              </li>
              <li>
                <a className="text-gray-600 hover:text-gray-800">
                  Uptime & Status
                </a>
              </li>
            </nav>
          </div>*/}
        </div>
      </div>
      <div className="bg-gray-100">
        <div className="container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row sm:space-x-4">
          <p className="text-gray-500 text-sm text-center sm:text-left">
            © 2021 My Product Hub
          </p>
          <Link
            to="/policies/terms-and-conditions"
            className="text-sm text-center sm:text-left text-gray-600 hover:text-gray-800"
          >
            Terms & Conditions
          </Link>
          <Link
            to="/policies/privacy-policy"
            className="text-sm text-center sm:text-left text-gray-600 hover:text-gray-800"
          >
            Privacy Policy
          </Link>
        </div>
      </div>
    </footer>
  );
}
