import PropTypes from "prop-types";
import React from "react";
import { useLocation } from "@reach/router";
import CookieConsent from "react-cookie-consent";
import {initializeAndTrack} from "gatsby-plugin-gdpr-cookies";
import { Link } from "gatsby";

import Header from "../sections/header";
import Footer from "../sections/footer";
import Waitlist from "../sections/waitlist";

function Layout({ children, showWaitlist = true }) {
  const location = useLocation();
  return (
    <div className="flex flex-col min-h-screen font-serif text-gray-900">
      <Header />
      <main className="flex-1 w-full min-h-full relative">{children}</main>
      {showWaitlist === true && <Waitlist />}
      <Footer />
      <CookieConsent
        disableStyles={true}
        location="bottom"
        buttonText="I Agree"
        cookieName="myproducthubconsent"
        containerClasses="flex flex-row justify-between text-base sm:text-lg fixed text-white bg-black p-4 w-full space-x-4"
        buttonClasses="p-2 text-base sm:text-md bg-mph-orange rounded-lg text-white"
        overlayClasses="w-full h-full fixed block top-0 left-0 bg-gray-300 opacity-75 z-50"
        expires={150}
        overlay={true}
        onAccept={() => initializeAndTrack(location)}
      >
        We use cookies to ensure that we give you the best experience on our
        website. By continuing to use & browse this site, you agree to our {" "}
        <Link to="/policies/privacy-policy" className="text-gray-300 underline">
          privacy policy
        </Link>{" "}
        and you accept our use of cookies.
      </CookieConsent>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  showWaitlist: PropTypes.bool,
};

export default Layout;
