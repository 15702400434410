import { Link } from "gatsby";
import React from "react";

import RocketSVG from "../images/Rocket.svg";

function Waitlist() {
  return (
    <section className="bg-mph-light-gray" id="waitlist">
      <div className="w-full flex flex-col items-center justify-center space-x-5">
        <div className="max-w-6xl w-full flex flex-col sm:flex-row py-4">
          <div className="hidden sm:w-1/2 p-4 sm:flex items-center">
            <img src={RocketSVG} />
          </div>
          <div className="flex flex-col w-full items-center justify-center sm:w-1/2 p-4 space-y-5">
            <span className="font-bold text-2xl sm:text-5xl text-black">
              Take control of your <br />
              product development
            </span>
            <div className="w-full md:w-1/2 py-4 flex flex-col justify-center items-center">
              <Link
                to="https://app.myproducthub.com?action=Register"
                target="_blank"
                className="p-2 text-lg sm:p-6 rounded-lg bg-mph-ocean-blue text-white sm:text-2xl cursor-pointer transform transition duration-500 hover:scale-110"
              >
                Get Started For Free
              </Link>

              <p className="text-sm text-gray-500 mt-4">
                *14 Day Free Trial. No Card Required.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Waitlist;
