import { Link } from "gatsby";
import React, { useState } from "react";
import Logo from "../images/logo.svg";

function Header() {
  const [isExpanded, toggleExpansion] = useState(false);
  const [tab, toggleTab] = useState(null);

  return (
    <header className="bg-mph-light-gray w-full sticky top-0 z-50">
      <div className="container flex flex-wrap items-center justify-between px-5 p-4 mx-auto">
        <Link to="/">
          <h1 className="flex items-center text-black no-underline sm:w-full w-3/4">
            <img alt="My Product Hub" src={Logo} />
          </h1>
        </Link>

        <button
          className="items-center block px-3 py-2 text-black border border-black rounded md:hidden"
          onClick={() => toggleExpansion(!isExpanded)}
        >
          <svg
            className="w-3 h-3 fill-current"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>

        <nav
          className={`${
            isExpanded ? "block" : "hidden"
          } md:block md:items-center w-full md:w-auto`}
        >
          {[
            {
              title: "Features",
              dropdown: true,
              items: [
                {
                  title: "Feedback",
                  route: "/product/feedback",
                },
                {
                  title: "Testimonial",
                  route: "/product/testimonial",
                },
                {
                  title: "Idea Management",
                  route: "/product/ideas",
                },
                {
                  title: "Roadmaps",
                  route: "/product/roadmaps",
                },
              ],
            },
            {
              route: "/pricing",
              title: "Pricing",
            },
            // {
            //   title: "Resources",
            //   dropdown: true,
            //   items: [
            //     {
            //       title: "Blog",
            //       route: "/blog",
            //     },
            //     {
            //       title: "Glossary",
            //       route: "/glossary",
            //     },
            //   ],
            // },
          ].map((link) => {
            if (link.dropdown === true) {
              return (
                <div
                  key={link.title}
                  onClick={() =>
                    isExpanded === true && tab !== link.title
                      ? toggleTab(link.title)
                      : toggleTab(null)
                  }
                  className="group block mt-4 text-black no-underline md:inline-block md:mt-0 md:ml-6"
                >
                  <div className="inline-flex flex-row items-center">
                    <span className="cursor-pointer">{link.title}</span>
                    <svg
                      className="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path>
                    </svg>
                  </div>
                  <ul
                    className={`fixed ${
                      tab === link.title ? "block" : "hidden"
                    } group-hover:block bg-white shadow`}
                  >
                    {link.items.map((item) => (
                      <li
                        key={item.title}
                        className="w-full hover:bg-mph-light-gray p-4"
                      >
                        <Link key={item.title} to={item.route}>
                          {item.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              );
            }
            return (
              <div
                key={link.title}
                className="block mt-4 text-black no-underline md:inline-block md:mt-0 md:ml-6"
              >
                <Link key={link.title} to={link.route}>
                  {link.title}
                </Link>
              </div>
            );
          })}
          <div className="flex flex-row mt-4 text-black no-underline md:inline-flex md:mt-0 md:ml-6 space-x-3 items-center">
            <Link
              to="https://app.myproducthub.com"
              className="p-2 sm:p-4 rounded-lg bg-mph-ocean-blue text-white cursor-pointer"
            >
              Login
            </Link>
            <Link
              to="https://app.myproducthub.com?action=Register"
              target="_blank"
              className="p-2 sm:p-4 rounded-lg bg-mph-orange text-white cursor-pointer font-semibold"
            >
              Try For Free
            </Link>
          </div>
        </nav>
      </div>
    </header>
  );
}

export default Header;
